import queryString from 'query-string';

import { PAYMENT_INSTRUMENT_TYPE_TO_METHOD } from 'checkout/services/PaymentInstrumentService';
import type { PaymentStatusResponse } from 'checkout/types/checkout';
import type { CreateCustomerResponse } from 'shared/infra/account/types';
import type { SKUWithPriceData } from 'shared/infra/commerceLayer/prices/types';
import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import type { PaymentMethodContent } from 'shared/infra/contentful/types';
import { authedAxios } from 'shared/infra/http';
import type { PaymentInstrument } from 'shared/services/ProductService';
import type { Order, Product } from 'shared/services/tracker/types';
import type { CatalogState } from 'shared/store/catalog/types';
import type { OrderDetails, OrderLineItem } from 'shared/store/order/types';
import { getChannelFromSource } from 'shared/utils/channel-link';
import type { Maybe } from 'types/util';

import { isCashlessPolandOrder } from './CashlessPolandService';

export type PaymentMethodMounter = (
  pmId: string,
  orderId: string,
  pmRef: PaymentMethod,
) => Promise<void>;

const APM_REDIRECT_PATH = 'checkout/payment-callback';
const PAYMENT_ON_FILE_REDIRECT_PATH = 'checkout/payment-on-file-callback';

export const createCheckoutForOrder = async (
  orderId: string,
  paymentMethod: PaymentMethod,
  locale: string,
  totalAmountWithTaxesCents: number,
  redirectUrl?: string,
): Promise<CreateCustomerResponse> => {
  const { data } = await authedAxios.post<CreateCustomerResponse>(
    '/api/checkout',
    {
      orderId,
      locale,
      paymentMethod,
      totalAmountWithTaxesCents,
      redirectUrl,
    },
  );

  return data;
};

export const getCheckoutInformation = async (
  orderId: string,
): Promise<PaymentStatusResponse['data']> => {
  const { data } = await authedAxios.get<PaymentStatusResponse['data']>(
    `/api/order/payment-status/${orderId}?_vercel_no_cache=1`,
  );

  const checkoutInformation = {
    ...data,
    line_items: data.line_items.filter(
      (li) => li.item_type === 'bundles' || li.item_type === 'skus',
    ),
  };

  return checkoutInformation;
};

export const getRedirectUrlForAltPaymentMethod = (
  orderId: string,
  locale: string,
  paymentMethod: string,
): string => {
  const { host, protocol, search } = window.location;
  const domain = `${protocol}//${host}`;
  const channel = getChannelFromSource();
  const isSignupChannel = channel === 'signup';
  const redirectPathname = isSignupChannel
    ? APM_REDIRECT_PATH
    : `${channel}/${APM_REDIRECT_PATH}`;

  const parsedQuery = {
    ...queryString.parse(search),
    payment_method: paymentMethod,
  };
  const query = queryString.stringify(parsedQuery);
  const redirectUrl = `${domain}/${locale}/${redirectPathname}/${orderId}?${query}`;

  return redirectUrl;
};

export const getRedirectUrlForPaymentOnFileUpdate = (
  orderId: string,
  locale: string,
  instrumentType: PaymentInstrument['type'],
  location: Location,
): string => {
  const { host, protocol, search, pathname } = location;
  const domain = `${protocol}//${host}`;
  const channel = getChannelFromSource(pathname);
  const isSignupChannel = channel === 'signup';
  const redirectPathname = isSignupChannel
    ? PAYMENT_ON_FILE_REDIRECT_PATH
    : `${channel}/${PAYMENT_ON_FILE_REDIRECT_PATH}`;

  const parsedQuery = {
    ...queryString.parse(search),
    pof_type: instrumentType,
  };
  const query = queryString.stringify(parsedQuery);
  const redirectUrl = `${domain}/${locale}/${redirectPathname}/${orderId}?${query}`;

  return redirectUrl;
};

export const getPaymentMethodIdFromContent = (
  name: PaymentMethod,
  paymentMethods: PaymentMethodContent[],
): string => {
  const { id } = paymentMethods.find((entry) => entry.method === name);
  return id;
};

export const getDefaultPaymentMethod = (
  methods: PaymentMethodContent[],
  order: Pick<OrderDetails, 'totalAmountWithTaxesCents' | 'couponCode'>,
  isRecurringPaymentSupported: boolean,
  paymentInstrument: Maybe<PaymentInstrument>,
): PaymentMethodContent =>
  methods.find((entry) => {
    if (paymentInstrument) {
      return (
        entry.method ===
        PAYMENT_INSTRUMENT_TYPE_TO_METHOD[paymentInstrument.type]
      );
    }

    if (isCashlessPolandOrder(order)) {
      return entry.method === 'cashless_poland';
    }

    if (isRecurringPaymentSupported) {
      return entry.isSubscriptionSupported; // TODO: create CF migration rename field
    }

    return entry.method !== 'cashless_poland';
  });

export const formatPurchaseEventProducts = (
  lineItems: Pick<OrderLineItem, 'code' | 'quantity' | 'id'>[],
  catalog: CatalogState | SKUWithPriceData,
): Product[] => {
  if (!lineItems || !Array.isArray(lineItems)) {
    return [];
  }

  const eventProductsBySku = new Map<string, Product>();

  lineItems.forEach((lineItem) => {
    const catalogProduct = catalog[lineItem.code];

    eventProductsBySku.set(lineItem.code, {
      code: lineItem.code,
      trackingId: catalogProduct?.trackingId,
      price: catalogProduct?.formattedAmount,
      quantity: lineItem.quantity,
    });
  });

  return [...eventProductsBySku.values()];
};

export const formatPurchaseEventOrder = (
  orderDetails: OrderDetails,
  products: OrderLineItem[],
  catalog: CatalogState | SKUWithPriceData,
): Order => ({
  id: orderDetails.id,
  products: formatPurchaseEventProducts(products, catalog),
  total: orderDetails.totalAmountFloat,
});

export const getSelectedPaymentMethod = (
  paymentMethods: PaymentMethodContent[],
  id: string,
): PaymentMethodContent | undefined =>
  paymentMethods.find((entry) => id === entry.id);

export const hasPaymentFailed = (
  checkoutInfo: PaymentStatusResponse['data'],
): boolean => {
  const FAILED_STATUSES = ['failed', 'unpaid'];

  if (FAILED_STATUSES.includes(checkoutInfo.payment_status)) {
    return true;
  }

  // Edge case for PayPal implementation.
  // When cancelling a PayPal payment the payment status is authorized instead of failed.
  // TODO: Get back to e-payments and clarify the behavior.
  if (
    checkoutInfo.payment_method === 'paypal' &&
    checkoutInfo.payment_status === 'authorized'
  ) {
    return true;
  }

  return false;
};
